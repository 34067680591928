import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesMetaTeamsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Meta teams (Story)</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_beginner.png"
            alt="Early and late meta teams"
          />
        </div>
        <div className="page-details">
          <h1>Meta teams (Story)</h1>
          <h2>
            A list of early and late game meta teams that will help you progress
            though the story in NIKKE.
          </h2>
          <p>
            Last updated: <strong>15/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          This guide is dedicated for modes that uses Stage Penalty system (or
          deficit). Currently, the Deficit system is applied in Campaign, Event,
          Tribe Tower, Lost Sector. You can learn more about stage penalty here:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Stage penalty"
            link="/nikke/guides/stage-penalty"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_penalty.png"
                alt="Stage penalty"
              />
            }
          />
        </Row>
        <p>
          <strong>
            This guide is served for new players, so they can understand the
            cards in their hand, and prepare for later stages of the game. If
            you are a veteran, chances are you have already known what is best
            for you.
          </strong>
        </p>
        <p>
          Now if you just roll a (bunch) of character(s), and you want to know
          whether you can form a good hand of 5 Nikkes, follow me down the
          rabbit’s hole. The deeper it is, the less teams will be available to
          push through, mind you!
        </p>
        <SectionHeader title="Before u go" />
        <p>
          If you are new players, chances are you won’t have all of these
          characters to fill in your roster, even at low deficit. Just remember
          those rules:
        </p>
        <ul className="bigger-margin">
          <li>
            If you don’t have any <strong>Burst I</strong> listed below, use{' '}
            <NikkeCharacter mode="inline" slug="n102" enablePopover />
          </li>
          <li>
            If you don’t have any <strong>Burst II</strong> listed below, use{' '}
            <NikkeCharacter mode="inline" slug="anis" enablePopover />
          </li>
          <li>
            If you need another <strong>Burst III</strong> to form at least 2
            Burst III team, use{' '}
            <NikkeCharacter mode="inline" slug="rapi" enablePopover />
          </li>
        </ul>
        <p>
          To understand the terms: Burst I, Burst II, Burst III, we highly
          recommend you to take a look at this Team Building Guide first:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Team building"
            link="/nikke/guides/team-building"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_8.png"
                alt="Team building"
              />
            }
          />
        </Row>
        <SectionHeader title="Surface" subtitle="0 - 5% Deficit" />
        <p>
          This is the “easy mode”, and mostly only new players, whale or veteran
          can enjoy. All of your Nikke will have stat reduction which ranges
          from 0 ~ 25% based on the deficit.{' '}
          <strong>Most, if not all teams work in this deficit.</strong> As long
          as you can put a proper team with at least 1 Burst I, 1 Burst II and
          one Burst III, you are good to go. Because there are too many teams
          that can be used here, let's dig further.
        </p>
        <SectionHeader title="First Dive" subtitle="5 - 10% Deficit" />
        <p>
          This is when it starts getting a little bit hot, but if you have the
          right setup, sometimes even ONE character, you can still push through
          them easily.
        </p>
        <h4>1. One-Nikke-army</h4>
        <p>
          These teams only require 1 CORE Nikke, and you can fill the 4 rest
          slots with your strongest characters in the{' '}
          <Link to="/nikke/tier-list/">tier list</Link>. The structure will be X
          - X - Core Nikke - X - X, for example:{' '}
        </p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="n102" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapi" enablePopover />
            </div>
          </div>
        </div>
        <h5>Modernia</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
        </div>
        <p>
          If you can run your device (phone/PC) at 60 FPS consistently, Modernia
          is one of the best Story pushers for you. At this level of penalty,
          you can pretty much use any B1, B2 and Modernia carries everything
          with her sheer power alone.
        </p>
        <h5>Red Hood</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
        </div>
        <p>
          Same as Modernia, Red Hood is also one-Nikke-army on her own. The best
          part? She doesn’t even require 60 FPS. Throw whatever B1/B2 in this
          team and you are good at this level of penalty.
        </p>
        <h5>Scarlet: Black Shadow</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="scarlet-black-shadow"
            enablePopover
          />
        </div>
        <p>
          SBS is one of the best screen wipers + single target DPS in the game
          at a low level of penalty like this. Best part? She can hit a target
          and everyone else on the screen just dies. Completely automatically,
          perfect!
        </p>
        <h5>Scarlet</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
        </div>
        <p>
          Used to be the top carry in Nikke until Modernia, Red Hood shadow
          powered her. Don’t worry, she is still one of the best carry for you
          through Campaign, and you may want to use her whenever you have a
          spare slot - which will be described at the bottom of this guide.
        </p>
        <h5>Harran</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="harran" enablePopover />
        </div>
        <p>
          One of the good old carry. Harran can pierce and kill a lot of enemies
          at the same time. By doing so, she also self buffs herself more ATK,
          hence further enhancing her power to killing enemies. And she has a
          nice screen wiping too!
        </p>
        <p>
          <strong>
            Warning: Do not invest heavily in Harran. Stop at maximum 5/5/5
            unless you don’t plan on playing Nikke seriously but casually.
          </strong>
        </p>
        <h4>2. Tia + Naga</h4>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="tia" enablePopover />
          <NikkeCharacter mode="icon" slug="naga" enablePopover />
        </div>
        <p>
          The famous school girls of Missilis, known for their absurd “power”
          (whatever meaning you want). They are so strong that you can use any
          DPS with them and it still works wonderfully. Problem with them is,
          you need another Burst I here: Tia - Burst I - Naga - Burst III -
          Burst III.
        </p>
        <p>Example team:</p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <h4>3. Blanc + Noir</h4>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="blanc" enablePopover />
          <NikkeCharacter mode="icon" slug="noir" enablePopover />
        </div>
        <p>
          If Missilis has their duo, so does Tetra! In fact, Bunnies are the
          first duo introduced in Nikke. They heal more than Tia-Naga but
          provide less offensive potential. You may need to swap around these 2
          duo a lot later on, so if you have both pairs, raise them four!
        </p>
        <p>Example team:</p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Last time to auto" subtitle="10-18% Deficit" />
        <p>
          If you are a new player, you can still play Nikke automatically till
          around chapter 7 with this much deficit. Once you pass that point, you
          will find yourself losing A LOT by playing auto at this level of
          penalty (35 ~ 47% stat reduction).
        </p>
        <p>
          At this point, you won’t be able to use the “one-Nikke-army” teams
          anymore but need to draft proper ones. We will go through each of
          them.
        </p>
        <h4>1. Auto team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
        </div>
        <p>
          At this level, this team brings a lot to the table, and unless it’s a
          very nasty stage where a lot of oneshot mobs are spawned, you can use
          this team to play automatically. It’s balanced and well-rounded with
          heal, offensive, buffs.
        </p>
        <p>
          There are a few replacements for{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> and{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover /> here:
        </p>
        <ul className="bigger-margin">
          <li>
            <NikkeCharacter mode="inline" slug="scarlet" enablePopover /> has a
            good wipe, decent DPS on her own, and Blanc will heal her back even
            if she keeps lowering herself with her passive.
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="scarlet-black-shadow"
              enablePopover
            />{' '}
            is a great choice, but when the penalty starts breathing on her
            neck, her clearing power is not as impressive as at lower penalty.
          </li>
        </ul>
        <p>
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> can also
          be replaced if you don’t have her, of course:
        </p>
        <ul className="bigger-margin">
          <li>
            <NikkeCharacter mode="inline" slug="pepper" enablePopover /> is a
            decent healer with high offensive power. She doesn’t reduce
            cooldown, but she heals a lot with her burst.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="volume" enablePopover /> also
            provides Cooldown Reduction (CDR) just like Liter, except her other
            buffs feel pale compared to Liter.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="dorothy" enablePopover /> brings
            both CDR and offensive power on her own. Definitely a good
            alternative even if Noir holds her back a bit. Note that Dorothy is
            a Pilgrim so it’s not easy to roll her.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="n102" enablePopover /> is your
            go-to when you don’t have any of the above Nikke, go N102.
          </li>
        </ul>
        <h4>2. School girl team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team is less comfy than Bunnies team above, but they can deal way
          more damage. By using this team, you may want to turn off “Auto Burst”
          and control burst time yourself, because these Nikkes have very
          impactful bursts that need to be used at proper time to make the most
          out of them.
        </p>
        <p>
          Same as with the previous team,{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover />,{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover /> and{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> can be
          replaced by the same characters as listed there.
        </p>
        <h4>3. Old “God team”</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
        </div>
        <p>
          They used to be the most braindead team in Nikke until Blanc-Noir were
          released. This team has wipe, strong DPS, CDR, a shield that blocks
          most instant death attacks once in a while, and a nice passive heal
          from <NikkeCharacter mode="inline" slug="rapunzel" enablePopover />{' '}
          that doesn’t even require her bursting. Rapunzel can even come in
          clutch with her revival burst, saving your run from a disaster.
          Heavily out-classed by the Bunnies team, but if your Bunnies are not
          completely, you can go this route.
        </p>
        <h4>4. Manual play with lots of clicking</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This is the traditional team pushing story. They have semi wipe with{' '}
          <NikkeCharacter mode="inline" slug="privaty" enablePopover /> (mostly,
          just stun), boss killing with{' '}
          <NikkeCharacter mode="inline" slug="alice" enablePopover /> (requires
          extremely high investment but she’s worth), and damage buffers with{' '}
          <NikkeCharacter mode="inline" slug="maxwell" enablePopover /> +{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> +{' '}
          <NikkeCharacter mode="inline" slug="privaty" enablePopover />. Problem
          is, they require you to “aim” manually, and it’s not a good experience
          aiming those small flying Raptures from afar.{' '}
          <strong color="red">Highly NOT recommended</strong>, just putting them
          here as a tribute to the past.
        </p>
        <h4>5. Crown Team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Crown has creeped up the Meta. She performs exceptionally well and can
          buff the team tremendously well. Due to the possibilty of running
          Rapunzel, she can also perform very well in Auto Gameplay, Helping us
          to progress more swiftly in our campaign missions. Just be sure to put
          her in Position 1 (left most), otherwise Rapunzel may have a hard time
          giving her heals to activate Crown's skill 2.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Manual all the way" subtitle="18 ~ 30% Deficit" />
        <p>
          This is where most auto players have to stop. Enemies feel so tanky,
          enemies hit so hard, if you don’t cover and learn more advanced
          techniques, you are done for. Only a few teams can make their way to
          this deep, and they are:
        </p>
        <h4>1. Bunnies team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
        </div>
        <p>
          It all depends on the absolute number of Combat Power requirements. On
          low requirements like below 100k, you can still replace{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> with a
          Good B1, but after that, Liter is very essential for the team unless
          you can somewhat lower the deficit.
        </p>
        <p>
          For <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> and{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover />, they
          are also set in stone. You only swap{' '}
          <NikkeCharacter mode="inline" slug="scarlet" enablePopover /> /{' '}
          <NikkeCharacter
            mode="inline"
            slug="scarlet-black-shadow"
            enablePopover
          />{' '}
          in certain stages where you need to wipe enemies very fast.{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> can be
          replaced with{' '}
          <NikkeCharacter mode="inline" slug="alice" enablePopover />. At this
          point, you need to work on{' '}
          <NikkeCharacter mode="inline" slug="alice" enablePopover /> (10/4/10).
          She is not as versatile as{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> but she
          is still one of the best DPSs on fully invested.{' '}
        </p>
        <h4>2. School girl team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Same as above with more offensive power. School girls team is pretty
          much meta in all Story stages later on, with a few exceptions on the
          high healing demand stages, like against Bronchus. This team has a
          high risk variation, which will be mentioned later on.
        </p>
        <h4>3. Old “God team”</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team is still viable at this range, but it feels pretty
          challenging compared to the previous level of penalty. This team is
          set in stone, you may replace{' '}
          <NikkeCharacter mode="inline" slug="scarlet" enablePopover /> and{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover /> with:{' '}
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="scarlet-black-shadow"
            enablePopover
          />
          <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
          <NikkeCharacter mode="icon" slug="alice" enablePopover />
        </div>
        <p>
          But it’s all situational. You want to keep the original roster most of
          the time.{' '}
        </p>
        <h4>4. Alice Maxwell team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          At this point, you will feel suffocated with this team. They tolerate
          no mistake because you can’t even wipe anything with{' '}
          <NikkeCharacter mode="inline" slug="privaty" enablePopover />. You
          only have a small window to use{' '}
          <NikkeCharacter mode="inline" slug="alice" enablePopover /> to shoot
          everything down, or they chew you alive. Most of the try hard veterans
          will have to retry a lot here for a good run.
        </p>
        <h4>5. Crown Team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Crown is obviously still effective. If there is any team on full auto
          that can still work at this point, it is this one! A special mention
          to Scarlet: Black Shadow can be made to this point, since she will
          perform extremely well at low deficits.
        </p>
        <SectionHeader title="Pure Evil" subtitle="30 ~ 35% Deficit" />
        <p>
          Everything kills you. You take forever to kill enemies. Yes, welcome
          to the malicious level of penalty!
        </p>
        <h4>1. Bunnies team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This is the last stop for the Bunnies team. They are good, don’t get
          me wrong. But their limit starts showing at this point.{' '}
          <NikkeCharacter mode="inline" slug="blanc" enablePopover /> can only
          debuff enemies on screen at the moment she bursts, but enemies will
          eventually spawn after that and all require you to answer them very
          quickly or that run ends in a retry. Also even with the debuff, you
          still take forever to kill Elite Raptures.
        </p>
        <h4>2. School girl team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Surprisingly, this is where you start relying heavily on{' '}
          <NikkeCharacter mode="inline" slug="tia" enablePopover /> and{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover /> instead.
          Their offensive capabilities allow you to “cheese” through a lot of
          stages by killing enemies before they can kill you.
        </p>
        <h4>3. Alice Maxwell team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          It takes a sheer will, dedicated effort, brilliant strategy and LOTS
          OF luck to even use this team here. Only the best players may find
          success with this old fashioned team, and even then, they have their
          way with either Bunnies team or School girls team.
        </p>
        <p>Just watch in awe. DO NOT ATTEMPT</p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="vRwZ6F8tZ_A" />
          </Col>
        </Row>
        <h4>4. Manual Heavy, Infinite Ammo Crown Team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
        </div>
        <p>
          With Privaty and Alice, this team shifts from the common Auto friendly
          pattern to the manual extensive Alice. If you can manage to shoot 3.5
          - 4 shots/s, this comp can perform at very high levels with ease,
          otherwise you can still perform well if you use Modernia over Alice.
          All the usual B3 choices like Scarlet, Scarlet:Black Shadow, A2,
          Emilia still work well till this point. The lower your deficit, the
          better Scarlet:Black Shadow will perform comparative to others. If you
          don't want to run the Infinite Ammo Crown team, you can always revert
          back to using Naga/Rapunzel in the 5th spot to test out your limits.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Hell" subtitle="35 ~ 45% Deficit" />
        <p>
          At 35% deficit, you start questioning yourself, why do you keep doing
          this? What’s the reward for torturing yourself like this? If you still
          want to push, then there’s technically only 1 team left to use. It has
          a lot of variations so be sure to stick around for all of them.
        </p>
        <h4>1. The School Girls team - God Comp #1</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
              <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
            </div>
          </div>
        </div>
        <p>Key Point to Note:</p>
        <ul>
          <li>
            RedHood is showcased as a solo Burst 1 unit here! A detailed
            explanation for how to play with RedHood as Solo Burst 1 will be
            given in the next section!
          </li>
          <li>
            Options for what B3s to use are given in the next section as well!
          </li>
          <li>
            D: Killer Wife is usually used when you can’t tolerate 4s cooldown
            between first and second full burst, which mostly ends your run
            immediately after Chapter 23.
          </li>
        </ul>
        <p>
          To push at this level, not only do you have to fully invest in these
          Nikkes, but also you have to rely on good Overload rolls, especially
          Elemental advantage.
        </p>
        <p>
          And of course, your own SKILLS! This is where you can brag among all
          other commanders how good you are, because you are pushing under a
          penalty that feels impossible. This Team is the best replacement (yes,
          replacement) for the Crown Team, and outperforms it (sometimes)
          depending on the stage design, but 99% of the time{' '}
          <NikkeCharacter mode="inline" slug="crown" enablePopover /> will be
          better! <NikkeCharacter mode="inline" slug="red-hood" enablePopover />{' '}
          as B1 is more meta than{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> (more info
          in next section). However,{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> is used
          when it feels it is necessary that more than 2-3 Bursts are needed to
          beat a stage, which is common on Base Defence and Defence (the one
          with a wall on the right side) type stages, or it feels necessary to
          get the 3rd Burst faster.
        </p>
        <p>Example gameplay (with Liter):</p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="6VTB1sxahV4" />
          </Col>
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="eduLRbry2lY" />
          </Col>
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="-B8ksiCQJTk" />
          </Col>
        </Row>
        <p>Example gameplay (with Red Hood B1):</p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="SbTuNE19nEQ" />
          </Col>
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="NMDYy5UMdkg" />
          </Col>
        </Row>
        <h4>2. The King’s Team - God Comp #2</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
              <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
            </div>
          </div>
        </div>
        <p>Key Point to Note:</p>
        <ul>
          <li>Red Hood is shown as a Solo Burst 1 Unit! More info below.</li>
          <li>B3 and FLEX choices are given below.</li>
          <li>
            D: Killer Wife is usually used when you can’t tolerate 4s cooldown
            between first and second full burst, which mostly ends your run
            immediately after Chapter 23.
          </li>
        </ul>
        <p>
          <NikkeCharacter mode="inline" slug="crown" enablePopover /> is the new
          King of B2s, and she will carry you through thick and thin. This God
          Comp is better than the old God Comp of{' '}
          <NikkeCharacter mode="inline" slug="tia" enablePopover /> and{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover />, simply
          because of Crown! She allows our DPS to get a variety of high value,
          usually undiluted buffs, along with shields to all her allies, as well
          as a special taunt that is one of the most clutch things that you can
          survive with if used properly. Not only that, but Crown allows for
          extreme flexibility in team building, as she is not bound to any fixed
          Nikke, like how Tia is bound to Naga. The Flex Spot is an extremely
          competitive spot, which can be used by a multitude of support or DPS
          Nikkes. The most used Nikke in Flex is Naga, but more information can
          be found further below on this topic!
        </p>
        <p>
          Watch in awe as this guy breezes through stages at more than 39%
          deficit with Crown! (With D:KW):
        </p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="3QxtSsOfVSQ" />
          </Col>
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="zOacKhNRVVM" />
          </Col>
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="-Pd125xxKP3c" />
          </Col>
        </Row>
        <h5>RedHood as Solo Burst 1</h5>
        <p>
          When you use{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> as
          burst 1 only, you have an insanely high amount of attack buffs over
          10s, and you can even do it twice before she goes on cooldown. It
          allows you to bring 2 DPS, which will a lot of times be{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover /> + Nikke
          that matches the “Weak Element” of the Stage. Ideally, the B3 choices
          depend on the stage, and aren’t as easy as - this stage is Wind Weak,
          so I will pick{' '}
          <NikkeCharacter
            mode="inline"
            slug="scarlet-black-shadow"
            enablePopover
          />
        </p>
        <p>
          At this deficit, you want to study the stage step by step and see what
          best helps you progress from one wave to the other wave, and what the
          next wave requires. At times, you might need to i-frame to dodge
          Remnant Raptures, or hold your burst while your{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover /> kills
          off Raptures outside of Burst.
        </p>
        <p>
          <strong>
            How to Use RedHood B1? / How to control Burst Rotations?
          </strong>
        </p>
        <p>
          In a 2-Burst Win, you shouldn’t feel much different from using your
          usual teams. You might need to be more patient between your 2 bursts,
          and may need to kill raptures outside of Burst. In this, you use the
          first burst to clear waves, and the 2nd burst to focus the stage boss
          and kill it fast. A 2-burst may be used when the player wants the same
          Nikke to burst 2 times, while the other Nikkes provide support damage.
        </p>
        <p>
          In a 3-Burst Clear, you use the first 2 Bursts for wave clearing, and
          then the 3rd burst to rush down and kill the boss. You should easily
          be able to do 2 bursts without any major hindrance. But after that, if
          you want to burst a 3rd time, you’ll face the trouble of
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover />{' '}
          cooldown. This is something most people should be unfamiliar with if
          they’ve been using{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> or other
          B1-CDR units as B1. To overcome this barrier is a skill and game
          understanding barrier in Nikke to most people. To reach your 3rd
          burst, you will have to save one rapture from a wave by killing all
          enemies but one, and then covering all your Nikkes.
        </p>
        <p>
          Ideally, this should be a harmless rapture like a healer, buffer or
          elite that doesn’t do high damage, so that you can wait out 30 seconds
          after full burst ends, to have your burst ready again. Now we can
          uncover our Nikkes and kill the one rapture we left alive, and when
          the boss drops, we burst again and focus on the boss! You can make use
          of <NikkeCharacter mode="inline" slug="tia" enablePopover /> Burst or{' '}
          <NikkeCharacter mode="inline" slug="crown" enablePopover /> Skill 2
          between 2nd and 3rd bursts, i.e. while{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> is on
          cooldown to boost your team’s damage if needed.
        </p>
        <h5>Point to note</h5>
        <p>
          Make sure that your{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> does
          not have super high ATK on her overload gear! Otherwise she might
          steal <NikkeCharacter mode="inline" slug="naga" enablePopover /> skill
          2 from your main DPS (if you are running Crown+Naga) when they need
          that extra 40% core hit bonus! If your{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> happens
          to have too many ATK rolls, you might need to use specific Nikkes that
          boost their own ATK during burst so that they are able to benefit from{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover /> Core Hit
          buffs to the fullest! Just make sure that she doesn’t steal that core
          bonus from our beloved{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover />! If
          your <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> as
          B1 seems to steal{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover />
          ’s core hit buffs, use{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> or{' '}
          <NikkeCharacter mode="inline" slug="d-killer-wife" enablePopover />{' '}
          over her! Example of Red Hood Solo B1
        </p>
        <p> Example of 3 Burst Stage Clear </p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="Ko77VOTVf_Y" />
          </Col>
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="IBwZaX9IJDs" />
          </Col>
        </Row>
        <p>Example of how to use Crown’s Taunt effectively:</p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="G89PKSH5d3s" />
          </Col>
        </Row>
        <h4>Flex Options for Crown’s Team</h4>
        <h5>Naga</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="naga" enablePopover />
        </div>
        <p>
          The highlight pair for{' '}
          <NikkeCharacter mode="inline" slug="crown" enablePopover />! Naga
          leaves her sister to join the kingdom! The synergy is very simple, and
          insanely effective. Naga can heal Crown and activate Crown’s skill 2,
          which leads to more damage buffs for the team. In return, Crown can
          provide a shield to Naga which activates Naga’s Skill 1, which results
          in even more damage buffs for the team! In fact, the buffs are so
          insane that Naga and Crown currently provide the highest offensive
          support when paired together! Hence, there is simply no question about
          it that Crown and Naga will be seen quite often in Campaign lowest
          clear logs!
        </p>
        <p>
          While Naga may not be the best choice for every stage, she is
          definitely the choice that can provide the highest buffs to your DPS!
          Naga also provides healing and cover healing both, something which
          would be missing if we use other units over her.
        </p>
        <h5>Other Flex Choices</h5>
        <p>
          <NikkeCharacter mode="inline" slug="maxwell" enablePopover />: is
          mostly used for her ATK Buffs, but her high damage nuke can also take
          care of elites, or can even be used to nuke the boss.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="modernia" enablePopover />: Not
          using her Burst? Slot her in as a Single target DPS! Cannot go wrong
          with using Modernia! When Modernia is not bursting, her focus target
          DPS is actually higher than when she is bursting! So she will help you
          focus down any high HP raptures you are struggling against!
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="privaty" enablePopover />:{' '}
          <NikkeCharacter mode="inline" slug="crown" enablePopover /> pushes her
          value up by a lot. Due to team-wide reload buffs, paired with Crown
          she can virtually allow us to run an Infinite Ammo team, something
          that <NikkeCharacter mode="inline" slug="alice" enablePopover /> can
          benefit from extremely. She can also be used as main B3 for her unique
          Stun mechanic, while she also buffs ally ATK.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="rapunzel" enablePopover /> /{' '}
          <NikkeCharacter mode="inline" slug="bay-goddess-mary" enablePopover />{' '}
          / <NikkeCharacter mode="inline" slug="marciana" enablePopover />:
          Replacements for{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover />. They are
          more defensive/casual choices but are still viable choices. Provide
          healing to the team. Can also activate and increase uptime of{' '}
          <NikkeCharacter mode="inline" slug="crown" enablePopover /> Skill 2.
          Overall lower offensiveness than using other units, so think of it as
          a more casual approach to the game. Remember to place Crown in
          Position 1! The reason being that when all units are full HP, the
          leftmost nikke is healed, which can help Crown increase her Skill 2
          uptime.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="scarlet" enablePopover /> /{' '}
          <NikkeCharacter
            mode="inline"
            slug="sparkling-summer-anis"
            enablePopover
          />
          : A weaker version of what we try to achieve with using{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover /> in FLEX
          spot, will be possibly stronger on their own element. We are simply
          using them as extra DPS
        </p>
        <h5>Crown vs Tia - Showdown for Naga!</h5>
        <p>
          We did indeed mention that Crown is better than Tia, but we would like
          to explain some reasoning behind it as well, so that players have an
          idea on when to use whom:
        </p>
        <p>
          Because Crown provides individual shields for all Nikkes, and also
          provides Attack Damage, exactly like Tia (and they even have taunt
          too), Crown competes Tia for a spot in campaign. Let’s take a look at
          Crown.
        </p>
        <ul>
          <li>S1 gives roughly 43% ATK to your DPS, which lasts forever</li>
          <li>
            S1 gives 44.35% reload time to everyone, which lasts forever too
          </li>
          <li>S2 gives 20.99% Attack Damage with 50% uptime roughly</li>
          <li>S2 also taunts 5s every 15s.</li>
          <li>
            Burst gives small shields to everyone, which also grants 36.24%
            Attack Damage that lasts forever.
          </li>
        </ul>
        <p>What does Tia have?</p>
        <ul>
          <li>S1 gives 32.11% Attack Damage that lasts 66% of the match</li>
          <li>S2 taunts every 5 hits, so it’s kinda on-demand</li>
          <li>
            Burst gives everyone a small shield, and a big shield on self.
          </li>
        </ul>
        <p>
          <i>Assumes all skills are at level 10.</i>
        </p>
        <p>
          As you can see, Crown gives WAY TOO MUCH Offensive buffs here. And
          because she gives individual shields, she also triggers Naga S1. 90%
          of the time, there’s no place for Tia in campaign at all.
        </p>
        <p>
          However, there might be a stage where you want to use Tia due to her
          on-demand taunt buff, which might be a case in stages with suicide
          raptures. A major scenario where Tia is better than Crown is when you
          need damage while RedHood Burst 1 is on cooldown (which is the
          downtime between 2nd and 3rd Burst while using RedHood as solo B1
          unit). Crown requires progression from Burst 1 stage to provide
          shields and activate Naga’s Skill 1, while Tia has no such
          restriction! So even though Crown provides overall higher buffs with
          Naga, she cannot buff the team as much as Tia during this period. So
          keep in mind if you require DPS during this downtime, Tia will be your
          best bet.
        </p>
        <p>
          Notice how the player uses Tia’s burst to boost damage during no Full
          Burst:
        </p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="rOEZOGavnBo" />
          </Col>
        </Row>
        <p>
          Overall, as you can see, while Tia still has her say in some stages,
          BUT Crown should still remain the best choice of support over Tia. You
          can never go wrong with Crown, so if you’re confused who to use, use
          Crown! Remember, we are in Hell! Being on the offensive is the best
          defensive strategy! Kill them before they kill you, there’s no other
          way!
        </p>
        <h4>3. B3 choices for both God Comps</h4>
        <h5>Modernia</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
        </div>
        <p>
          <NikkeCharacter mode="inline" slug="naga" enablePopover /> is a core
          hit buffer, and{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover />{' '}
          benefits the most from Core Hit Buffs, be it out of Burst or in Burst.
          Hence, when using{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover />, it is
          probably ideal to make use of{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover /> to improve
          her damage. Since she is a Machine Gun, she can hit the core of
          enemies with pin-point accuracy. When using RH as B1, not only is her
          Burst getting{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover />
          ATK buff for 10s compared to{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> 5s, her
          constant AoE during Burst targets the cores of all raptures it
          auto-locks on, hence benefitting from{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover /> to its
          fullest!
        </p>
        <p>
          When the boss drops, you will often notice that players do not burst
          with <NikkeCharacter mode="inline" slug="modernia" enablePopover />,
          and use the other Nikke’s Burst. This is because Modernia will provide
          the absolute best support damage to finish off a boss! This does not
          mean that Modernia will have a permanent spot as B3, but you will
          definitely see her being used 90% of the time!
        </p>
        <h5>Alice</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="alice" enablePopover />
        </div>
        <p>
          High Value Unit with one major drawback: Intense Manual Usage. If you
          can get over that drawback, and can manage 3.5 to 4 shots per second
          with Alice, Crown with her reload speed makes her creep up as the best
          choice alongside{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover />!
        </p>
        <p>
          Her high DPS output Burst can be used for Boss Killing, as well as
          taking care of mob waves that are clumped together since she has
          pierce. One problem Alice has is that she needs a lot of Ammo OL gear,
          which can be circumvented thanks to{' '}
          <NikkeCharacter mode="inline" slug="crown" enablePopover />,
          especially when used with Resilience Cube +{' '}
          <NikkeCharacter mode="inline" slug="privaty" enablePopover />! This
          provides infinite ammo to her due to insane reload speed buffs,
          allowing her to shoot non stop during Burst. When Burst ends, she is
          at full ammo, so she can spam her shots to get to the next Full Burst
          in no time! Alice is now a freak of nature since the addition of Crown
          reduces her OL dependency severely. Using{' '}
          <NikkeCharacter mode="inline" slug="privaty" enablePopover /> or{' '}
          <NikkeCharacter mode="inline" slug="maxwell" enablePopover /> over{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover /> is
          definitely a viable choice when used with Crown.
        </p>
        <h5>Other B3 Choices</h5>
        <ul className="bigger-margin">
          <li>
            <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> : Not
            using her as a B1? Use her as the main B3! The absolute best choice
            B3 when not using her as B1, even above Modernia! Red Hood is simply
            the best when it comes to either handling mobs or focusing on one
            single target. Use her. Always!
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="scarlet" enablePopover /> : AoE
            wipe is a unique utility, the quality of which no other Nikke can
            match. Additionally, she has amazing DPS output, notably outside of
            Burst due to her constant self ATK Buff.
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="scarlet-black-shadow"
              enablePopover
            />{' '}
            works well as a support screen-wipe, but her main focus will be to
            focus on nuking the stage boss “target”, after someone clears the
            mobs for her. The lower your penalty, the better she will perform
            for you. This does not mean, however, that she is incapable of
            wiping waves. If the waves come in numbers, but they drop slow
            enough and in smaller groups, SBS can perform majestically well.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="maxwell" enablePopover /> is
            mostly used for her Buffs, but her high damage nuke can also take
            care of elites, or can even be used to nuke the boss.
          </li>
        </ul>
        <h5>Situational Use B3's</h5>
        <ul className="bigger-margin">
          <li>
            <NikkeCharacter mode="inline" slug="snow-white" enablePopover /> is
            used to either nuke the boss instantly when it drops, or used to
            nuke a group of high HP elites that would otherwise do too much
            damage in a small amount of time.
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="sparkling-summer-anis"
              enablePopover
            />
            : Super strong on her own element, still usable on other elements.
            When the boss drops, Anis will allow us to completely focus on the
            boss as her Skill 2 takes care of those pesky raptures that try to
            kill us when we’re trying to rush down the boss! Synergy with
            <NikkeCharacter mode="inline" slug="privaty" enablePopover /> as
            well
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="a2" enablePopover /> /{' '}
            <NikkeCharacter mode="inline" slug="emilia" enablePopover /> : Don’t
            have <NikkeCharacter mode="inline" slug="modernia" enablePopover />{' '}
            or <NikkeCharacter mode="inline" slug="scarlet" enablePopover />?
            These 2 are your best bet for clearing waves. Constant wave clearing
            ability is not to be underestimated. Both of them are unique units
            that will also have buffs outside of Full Burst, and will be able to
            hit each rapture with their full strength, clearing the screen due
            to their huge splash radius. Emilia is a better version of A2. The
            reason is simply because Emilia doesn’t need to Burst to provide her
            Wave Clearing ability, and her buffs aren’t lost when she goes below
            a certain HP threshold. Furthermore, Emilia does not reduce her HP
            like A2, giving her more edge, if having a ×1.59 multiplier on every
            shot she makes wasn’t enough. Additionally, Emilia can be spammed
            with to gain burst gen, while A2 can prove difficult to Manually
            use.
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="ludmilla-winter-owner"
              enablePopover
            />{' '}
            : Super strong on her own element! Being an MG, can constantly hit
            the core of raptures, allowing her to enjoy Naga’s core benefits to
            the fullest.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> :
            While we promote using her as a B1, she may also be used as a Burst
            3! But in very specific situations. Even if you’re using her as a
            B1, you can use her as B3! This confirms the limit of your Bursts in
            the stage to 2. The use of this depends on the stage. You will
            sometimes find this useful in iron weak stages. Due to the nature of
            this suggestion, the following video reference should help
            understand how it works:
          </li>
        </ul>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="vMdI8DIGyCw" />
          </Col>
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="r8nrFJsT6S4" />
          </Col>
        </Row>
        <h5>Elemental Based Choices</h5>
        <h6>Against Iron weak stage:</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
          <NikkeCharacter mode="icon" slug="snow-white" enablePopover />
          <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
          <NikkeCharacter mode="icon" slug="alice" enablePopover />
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
        </div>
        <h6>Against Fire weak stage:</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
          <NikkeCharacter mode="icon" slug="alice" enablePopover />
          <NikkeCharacter mode="icon" slug="a2" enablePopover />
          <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
        </div>
        <h6>Against Electric weak stage:</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
          <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
          <NikkeCharacter mode="icon" slug="alice" enablePopover />
          <NikkeCharacter
            mode="icon"
            slug="sparkling-summer-anis"
            enablePopover
          />
        </div>
        <h6>Against Water weak stage:</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="ludmilla-winter-owner"
            enablePopover
          />
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
          <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
          <NikkeCharacter mode="icon" slug="privaty" enablePopover />
          <NikkeCharacter mode="icon" slug="emilia" enablePopover />
          <NikkeCharacter mode="icon" slug="alice" enablePopover />
        </div>
        <h6>Against Wind weak stage::</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="scarlet-black-shadow"
            enablePopover
          />
          <NikkeCharacter mode="icon" slug="alice" enablePopover />
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
          <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
        </div>
        <p>
          Anything beyond 45% is currently thought to be impossible, and at 50%,
          all of your stats are reduced by 99%, preventing you from going
          further. Congratulations, you have reached the bottom pit of hell!
          Give yourself a pat on the back, you deserve it.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMetaTeamsPage;

export const Head: React.FC = () => (
  <Seo
    title="Meta teams (Story) | NIKKE | Prydwen Institute"
    description="A list of early and late game meta teams that will help you progress though the story in NIKKE."
    game="nikke"
  />
);
